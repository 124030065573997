import React, { useContext, useEffect, useRef } from "react";
import * as staticConstants from "../../constants/staticConstants";
import { GlobalContext } from "App";

const AvatarCard = ({ userDetails, toggleEditAvatarModal, enableEditing, handleEditNickName, toggleEnableEditing, handleUpdateNickName }) => {
    const ref = useRef(null);
    const { authState } = useContext(GlobalContext);

    useEffect(() => {
        if (enableEditing) {
            ref.current.focus();
            ref.current.select();
        }
    }, [enableEditing]);

    return (
        <>
            <div className="balance-card yellowCard mb-3">
                <div className="innerCard py-3">
                    <div className="avatar-box2">
                        <img src={userDetails.profileImgUrl ? userDetails.profileImgUrl : "assets/img/avtaar-user.png"} className="avtaar-img" alt="Profile" />
                        {authState?.userDetails?.levelIconImageUrl && <img className="badges" src={authState?.userDetails?.levelIconImageUrl} alt="levelIcon" />}
                        <button className="edit-avtaar underline-btn" onClick={toggleEditAvatarModal}>
                            <img src="assets/img/edit-avtaar.png" alt="edit" style={{ width: 35 }} />
                        </button>
                    </div>
                    <div className="pt-3">
                        <div className={`customize-input text-center ${enableEditing ? "focusOn" : ""}`}>
                            <div className=" position-relative">
                                <input
                                    ref={ref}
                                    disabled={!enableEditing}
                                    type="text"
                                    className="form-control text-white"
                                    value={userDetails.nickName}
                                    onChange={(e) => {
                                        e.target.value.length <= 10 && /^[a-zA-Z]*$/.test(e.target.value) && handleEditNickName(e.target.value);
                                    }}
                                    onBlur={toggleEnableEditing}
                                    onFocus={toggleEnableEditing}
                                />
                                {enableEditing ? (
                                    <button className="edit-position underline-btn custom-green-text" onClick={handleUpdateNickName}>
                                        {staticConstants.UPDATE}
                                    </button>
                                ) : (
                                    <button className="edit-position underline-btn" onClick={toggleEnableEditing}>
                                        <img src="assets/img/edit-name.png" alt="edit" style={{ width: 18 }} />
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="mt-2 customize-input text-center">
                            <p className="mb-0 text-black font-size-14">
                                {userDetails.countryCode} {userDetails.mobileNumber}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AvatarCard;
