import React, { useContext, useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import CustomHeader from "./common/CustomHeader";
import { useNavigate } from "react-router-dom";
import { LOGOUT } from "reducer/actions/userActions";
import toast from "react-hot-toast";
import { GlobalContext } from "App";
import ModalBox from "./common/Modal";
import { callGetApi } from "api/ApiCaller";
import { CERTIFICATE_ENABLED, GET_LEVEL_LIST_GET } from "api/ApiConst";
import { NO_ERROR } from "api/constants";
import { Description, DownloadIcon } from "./common/SvgIcon";

const Setting = () => {
    const [showHowToPlayModal, setShowHowToPlayModal] = useState(false);
    const [showLevelModal, setShowLevelModal] = useState(false);
    const [levelDescription, setLevelDescription] = useState(null);
    const navigate = useNavigate();
    const { dispatchAuth, authState } = useContext(GlobalContext);
    const callVuPlexLogoutEvent = () => {
        window.sendMessageToVuplex("Logout", {});
    };
    const [helpData, setHelpData] = useState("");
    const [showHelp, setShowHelp] = useState(false);

    const [aboutUsData, setAboutUsData] = useState("");
    const [showAboutUs, setShowAboutUs] = useState(false);

    useEffect(() => {
        getLevelDescription();
    }, [])

    const getLevelDescription = () => {
        callGetApi(GET_LEVEL_LIST_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                const res = response.responsePacket;
                setLevelDescription(res);
            }
        })
    }

    const handleLogout = () => {
        // callPostApi(Logout_POST, {}, {}, (response) => {
        toast.success("User has been logged out!!", { id: "loggedOut" });
        dispatchAuth({ type: LOGOUT });
        callVuPlexLogoutEvent();
        // });
    };

    return (
        <Wrapper>
            <CustomHeader title={"Settings"} />
            <div className="p-3">
                <div className="yellowCard mb-3 mt-4">
                    <div className="innerCard py-3">
                        <div className="d-flex align-items-center justify-content-between" onClick={() => navigate('/profile')}>
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none">
                                        <g clipPath="url(#clip0_220_48)">
                                            <path d="M14.5282 13.9694C16.4474 13.9694 18.1092 13.2811 19.467 11.923C20.8248 10.5652 21.5132 8.90383 21.5132 6.98447C21.5132 5.06578 20.8248 3.40418 19.4668 2.04591C18.1087 0.688314 16.4471 0 14.5282 0C12.6088 0 10.9475 0.688314 9.58964 2.04613C8.23181 3.40396 7.54327 5.06555 7.54327 6.98447C7.54327 8.90383 8.23181 10.5654 9.58986 11.9232C10.9479 13.2808 12.6095 13.9694 14.5282 13.9694Z" fill="#323288" />
                                            <path d="M26.75 22.2997C26.7108 21.7346 26.6316 21.1182 26.515 20.4673C26.3973 19.8115 26.2457 19.1916 26.0643 18.6249C25.8769 18.0393 25.622 17.4609 25.307 16.9067C24.98 16.3314 24.5959 15.8305 24.1649 15.4183C23.7142 14.9871 23.1624 14.6404 22.5243 14.3875C21.8884 14.136 21.1837 14.0085 20.4299 14.0085C20.1339 14.0085 19.8476 14.13 19.2947 14.49C18.9544 14.7119 18.5563 14.9685 18.1121 15.2524C17.7322 15.4944 17.2175 15.7212 16.5819 15.9266C15.9617 16.1272 15.332 16.229 14.7105 16.229C14.089 16.229 13.4596 16.1272 12.8387 15.9266C12.2037 15.7215 11.6891 15.4947 11.3097 15.2526C10.8696 14.9714 10.4713 14.7148 10.126 14.4897C9.57371 14.1298 9.28719 14.0083 8.99115 14.0083C8.23712 14.0083 7.53265 14.136 6.897 14.3877C6.25935 14.6402 5.70732 14.9869 5.25619 15.4186C4.82541 15.831 4.44109 16.3317 4.11452 16.9067C3.79968 17.4609 3.5448 18.0391 3.35718 18.6252C3.17597 19.1918 3.02441 19.8115 2.90671 20.4673C2.79011 21.1173 2.7109 21.734 2.67174 22.3004C2.63324 22.8553 2.61377 23.4312 2.61377 24.0129C2.61377 25.5267 3.09499 26.7522 4.04395 27.656C4.98117 28.5478 6.22129 29.0003 7.72935 29.0003H21.693C23.2011 29.0003 24.4408 28.5481 25.3782 27.656C26.3274 26.7528 26.8086 25.5271 26.8086 24.0126C26.8084 23.4283 26.7887 22.8519 26.75 22.2997Z" fill="#323288" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_220_48">
                                                <rect width="29" height="29" fill="#323288" transform="translate(0.234863)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Account</p>
                                </div>
                            </div>
                            <div>
                                <img src="assets/img/rightIcon.png" alt="rightIcon" style={{ width: "18px" }} />
                            </div>
                        </div>
                        <div className="customHr my-3"></div>
                        <div className="d-flex align-items-center justify-content-between" onClick={() => setShowHowToPlayModal(true)}>
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/infoIcon.png" alt="infoIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">How to Play</p>
                                </div>
                            </div>
                            <div>
                                <img src="assets/img/rightIcon.png" alt="rightIcon" style={{ width: "18px" }} />
                            </div>
                        </div>
                        <div className="customHr my-3"></div>
                        <div className="d-flex align-items-center justify-content-between" onClick={() => setShowLevelModal(true)}>
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <Description />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Level description</p>
                                </div>
                            </div>
                            <div>
                                <img src="assets/img/rightIcon.png" alt="rightIcon" style={{ width: "18px" }} />
                            </div>
                        </div>
                        {CERTIFICATE_ENABLED === 'true' && authState?.userDetails?.levelTitle && <span>
                            <div className="customHr my-3"></div>
                            <div className="bg-white p-1 position-relative">
                                <div className="border border-secondary p-1">
                                    <div className="border border-primary p-1" >
                                        <div className="text-center">
                                            <img
                                                id="bannerImage"
                                                src="/assets/img/banner.png"
                                                alt="Banner Image"
                                                style={{
                                                    width: "15%",
                                                    margin: "0 auto",
                                                    textAlign: "center"
                                                }}
                                            />
                                        </div>
                                        <div className="fs-10 text-center fw-700 text-black mt-1" style={{ letterSpacing: "1px" }}>
                                            CERTIFICATE OF ACHIEVEMENT
                                        </div>
                                        <div className="fs-6 text-center mt-1"> This certificate is proudly presented to</div>
                                        <div className="fs-14 text-center py-1" style={{ color: "rgba(0, 82, 155, 1)", fontFamily: "Kaushan Script" }}>
                                            {authState?.userDetails?.fullName}
                                        </div>
                                        <div className="fs-6 text-center">
                                            for successfully completing <b style={{ color: "rgba(0, 82, 155, 1)" }}>Level {authState?.userDetails?.levelTitle}</b> of Fin-Ludo
                                        </div>
                                        <div className="fs-6 text-center mt-1">
                                            This certificate is awarded in recognition of your commitment and dedication to
                                            <br />
                                            advancing your understanding of financial concepts
                                        </div>
                                        <div className="mt-2">
                                            <div className="text-center">
                                                <img className="mx-auto text-center" src="assets/img/sin-img.png" alt="" style={{ width: "30%" }} />
                                            </div>
                                            <div className="text-center fs-8 fw-600 mt-1" style={{ lineHeight: "10px" }}>
                                                Anand Radhakrishnan
                                            </div>
                                            <div className="fs-6 text-center">
                                                <i>Managing Director</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Download-button">
                                    <a href="/Certificate" target="_blank" rel="noopener noreferrer" className="font-size-12 font-weight-600 text-decoration-underline  " style={{ color: "#ffb600 " }}><span className="button-text"><DownloadIcon />Download</span></a>
                                </div>
                            </div>
                            <p className="text-white fs-12 mb-0 mt-1">certificate is dependent on the number of questions you have answered correctly and the levels you have cleared.</p>
                        </span>}
                    </div>
                </div>
                <div className="yellowCard mb-3">
                    <div className="innerCard py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/logoutIcon.png" alt="logoutIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Logout</p>
                                </div>
                            </div>
                            <div>
                                <button className="btnRed text-black font-size-16 font-weight-600 py-2 px-4" onClick={handleLogout}>
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalBox
                show={showHelp}
                toggleModel={() => {
                    setShowHelp(false);
                    setHelpData("");
                }}
                modalClass={"sliderBlockModal"}
            >
                <div className="modal-content bg-yellowGradient border-none">
                    <div className="modal-body p-0">
                        <div className=" modal-body-header">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    setShowHelp(false);
                                    setHelpData("");
                                }}
                            >
                                <span className="cursor-pointer">
                                    <img src="assets/img/modal-close.png" alt="" style={{ width: "40px" }} />
                                </span>
                            </button>
                            <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                Help And Support
                            </h5>
                        </div>
                        <div className="p-2 staticPageText">
                            <div dangerouslySetInnerHTML={{ __html: helpData }} />
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox
                show={showAboutUs}
                toggleModel={() => {
                    setShowAboutUs(false);
                    setAboutUsData("");
                }}
                modalClass={"sliderBlockModal"}
            >
                <div className="modal-content bg-yellowGradient border-none">
                    <div className="modal-body p-0">
                        <div className=" modal-body-header">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    setShowAboutUs(false);
                                    setAboutUsData("");
                                }}
                            >
                                <span className="cursor-pointer">
                                    <img src="assets/img/modal-close.png" alt="" style={{ width: "40px" }} />
                                </span>
                            </button>
                            <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                About Us
                            </h5>
                        </div>
                        <div className="p-2 staticPageText">
                            <div dangerouslySetInnerHTML={{ __html: aboutUsData }} />
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox show={showHowToPlayModal} toggleModel={() => setShowHowToPlayModal(false)} modalClass={"sliderBlockModal h-100"}>
                <div className="modal-content bg-yellowGradient border-none h-100 ">
                    <div className="modal-body p-0 flex-column d-flex">
                        <div className=" modal-body-header">
                            <button type="button" className="btn-close" style={{ right: "15px" }} onClick={() => setShowHowToPlayModal(false)}>
                                <span className="cursor-pointer">
                                    <img src="assets/img/modal-close.png" alt="" style={{ width: "40px" }} />
                                </span>
                            </button>
                            <h5 className="modal-title text-black font-size-18 font-weight-600 px-2 text-center" id="exampleModalLongTitle">
                                How To Play
                            </h5>
                        </div>
                        <div className="d-flex flex-column flex-fill">
                            {/* <div className="iframeBox">
                                <div className="video-container">
                                    <CustomVideoPlayer
                                        videoSrc="https://youtube.com/shorts/iitnlJopu-k?si=fmu1p3yS8Xn2QAax%0A"
                                        posterImg={PosterImg}
                                    />
                                </div>
                            </div> */}
                            <div className="px-4 mt-2 ">
                                <a className=" text-decoration-underline text-yellow fw-600" href="https://youtube.com/shorts/iitnlJopu-k?si=fmu1p3yS8Xn2QAax%0A" target="_blank">How To Play(Video)</a>
                            </div>
                            <div className=" how-to-play flex-fill p-3 position-relative overflow-auto">
                                <ul className=" position-absolute">
                                    <li>Players: 2-4, each with a unique color.</li>
                                    <li>Start & Movement: Roll the dice to move tokens and score points.</li>
                                    <li>Capturing: Land on an opponent’s token to send it home and score points.</li>
                                    <li>Question mark (?) zone: Answer a question to move 6 steps and score points.</li>
                                    <li>Safe Zones: Star-marked squares protect tokens. Two same-colored tokens block captures.</li>
                                    <li>Winning: Highest points after given turns or within the time limit wins.</li>
                                    <li>Remember: Turn Limit; total 25 turns given to play, 🤍🤍🤍; 3 chances are given to miss a turn</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox show={showLevelModal} toggleModel={() => setShowLevelModal(false)} modalClass={"sliderBlockModal h-100"}>
                <div className="modal-content border-none h-100 Badges-modal-content ">
                    <div className="modal-body p-0 flex-column d-flex">
                        <div className=" modal-body-header">
                            <button type="button" className="btn-close" style={{ left: "15px" }} onClick={() => setShowLevelModal(false)}>
                                <span className="cursor-pointer">
                                    <img src="assets/img/modal-close.png" alt="" style={{ width: "50px" }} />
                                </span>
                            </button>
                            <h5 className="modal-title text-white font-size-24 font-weight-600 px-2 text-center" id="exampleModalLongTitle">
                                Badges
                            </h5>
                        </div>
                        <div className="d-flex flex-column flex-fill p-3 ">
                            <div className="Badges-content flex-fill p-3 d-flex flex-column gap-2 position-relative overflow-auto">
                                <div className=" position-absolute flex-column d-flex gap-3 start-0  end-0 top-0 p-3 ">
                                    {levelDescription && levelDescription.length > 0 && levelDescription.map((level) => (
                                        <div key={level.recordId} className="Badges-tile align-items-center gap-2 p-2">
                                            <div className="Badges-box">
                                                <img src="../assets/img/Badges-bg.png" alt="" />
                                                <div className="inner-content">
                                                    <img src={level?.levelIconImageUrl} alt="" />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="fs-16 fw-400 text-white">{level?.levelTitle}</div>
                                                <div className="fs-18 fw-500 text-yellow">{level?.levelDescription}</div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className="d-flex gap-4">
                                    <div>Title</div>
                                    <div>Badge</div>
                                    <div>Description</div>
                                    <div>Point From</div>
                                    <div>Point To</div>
                                </div>
                                {levelDescription && levelDescription.length > 0 && levelDescription.map((level) => (
                                    <div key={level.recordId} className="d-flex gap-4">
                                        <div>{level?.levelTitle}</div>
                                        <img className="" src={level?.levelIconImageUrl} alt="levelIcon" />
                                        <div>{level?.levelDescription}</div>
                                        <div>{level?.pointFrom}</div>
                                        <div>{level?.pointTo}</div>
                                    </div>
                                ))} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
        </Wrapper>
    );
};

export default Setting;
