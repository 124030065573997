import { callGetApiWithoutAuth } from 'api/ApiCaller';
import { GET_LEADERBOARD_LIST } from 'api/ApiConst';
import { NO_ERROR } from 'api/constants';
import { GlobalContext } from 'App';
import Wrapper from 'components/Wrapper'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Leaderboard() {
    const navigate = useNavigate();
    const { authState } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [topThreePlayer, setTopThreePlayer] = useState([]);
    const [remainingPlayers, setRemainingPlayers] = useState([]);
    useEffect(() => {
        getLeaderBoardList();
    }, []);

    const getLeaderBoardList = () => {
        setLoading(true);
        callGetApiWithoutAuth(GET_LEADERBOARD_LIST, (response) => {
            if (response.errorCode === NO_ERROR) {
                const res = response.responsePacket.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
                setTopThreePlayer(res.slice(0, 3));
                setRemainingPlayers(res.slice(3));
            }
            setLoading(false);
        }, () => {
            setLoading(false);
        })
    }
    return (
        <Wrapper>
            <div className="customHeader">
                <span className=" position-absolute" style={{ left: "10px" }}>
                    <img src="assets/img/backIcon.png" className="cursor-pointer" alt="back left arrow" style={{ width: "50px" }} onClick={() => navigate(-1)} />{" "}
                </span>
                <span className="headerTitle">Leaderboard</span>
            </div>
            <div className="mainContent-box">
                <div className="px-3 tabContent">
                    {/* <div className="Leaderboard-custom-btn">
                        <ul>
                            <li className="active">Last 7 Days</li>
                            <li>Last 30 Days</li>
                        </ul>
                    </div> */}
                    <div className="winner-div mt-4">
                        {topThreePlayer && topThreePlayer[1] && <div className="winner-div-2">
                            <div className="winner-profile-box text-center">
                                <div className="profile-box">
                                    {topThreePlayer[1].profileImageUrl ? <img className="img-box" src={topThreePlayer[1].profileImageUrl} alt="" /> : <img className="img-box" src="assets/img/winner-user-2.png" alt="" />}
                                    {topThreePlayer[1]?.levelIconImageUrl && <img className="badges" src={topThreePlayer[1]?.levelIconImageUrl} alt="levelIcon" />}
                                </div>
                                {/* <div className="profile-id-box mt-2">#{topThreePlayer[1].levelTitle ?? 1}</div> */}
                                <h6 className="fs-12 fw-500 text-white mb-1 mt-2">{topThreePlayer[1].nickName}</h6>
                                <h6 className="fs-16 fw-700 text-yellow2">{topThreePlayer[1].totalWinningAmount}</h6>
                            </div>
                            <div className="img-box">
                                <img className="w-100" src="assets/img/winner-2.png" alt="" />
                                <span className="text-content">2</span>
                            </div>
                        </div>}
                        {topThreePlayer && topThreePlayer[0] && <div className="winner-div-1">
                            <div className="winner-profile-box text-center">
                                <div className="profile-box">
                                    {topThreePlayer[0].profileImageUrl ? <img className="img-box" src={topThreePlayer[0].profileImageUrl} alt="" /> : <img className="img-box" src="assets/img/winner-user-1.png" alt="" />}
                                    {topThreePlayer[0]?.levelIconImageUrl && <img className="badges" src={topThreePlayer[0]?.levelIconImageUrl} alt="levelIcon" />}
                                </div>
                                {/* <div className="profile-id-box mt-2">#{topThreePlayer[0].levelTitle ?? 1}</div> */}
                                <h6 className="fs-12 fw-500 text-white mb-1 mt-2">{topThreePlayer[0].nickName}</h6>
                                <h6 className="fs-16 fw-700 text-yellow2">{topThreePlayer[0].totalWinningAmount}</h6>
                            </div>
                            <div className="img-box">
                                <img className="w-100" src="assets/img/winner-1.png" alt="" />
                                <span className="text-content">1</span>
                            </div>
                        </div>}
                        {topThreePlayer && topThreePlayer[2] && <div className="winner-div-3">
                            <div className="winner-profile-box text-center">
                                <div className="profile-box">
                                    {topThreePlayer[2].profileImageUrl ? <img className="img-box" src={topThreePlayer[2].profileImageUrl} alt="" /> : <img className="img-box" src="assets/img/winner-user-3.png" alt="" />}
                                    {topThreePlayer[2]?.levelIconImageUrl && <img className="badges" src={topThreePlayer[2]?.levelIconImageUrl} alt="levelIcon" />}
                                </div>
                                {/* <div className="profile-id-box mt-2">#{topThreePlayer[2].levelTitle ?? 1}</div> */}
                                <h6 className="fs-12 fw-500 text-white mb-1 mt-2">{topThreePlayer[2].nickName}</h6>
                                <h6 className="fs-16 fw-700 text-yellow2">{topThreePlayer[2].totalWinningAmount}</h6>
                            </div>
                            <div className="img-box">
                                <img className="w-100" src="assets/img/winner-3.png" alt="" />
                                <span className="text-content">3</span>
                            </div>
                        </div>}
                    </div>
                    <div className="d-flex align-items-center btn-blue-g text-white px-3 py-3 rounded-top-4">
                        <span className="fs-11 fw-500 col-2">Rank</span>
                        <span className="fs-11 fw-500 col-8 d-flex align-items-center ">Player & Level</span>
                        <span className="fs-11 fw-500 col-2 text-end">Points</span>
                    </div>
                </div>
                <div className=" position-relative flex-column overflow-auto d-flex mt-3 mx-3" style={{ flex: "1" }}>
                    <div className=" position-absolute overflow-hidden w-100 start-0 end-0">
                        {remainingPlayers && remainingPlayers.length > 0 && remainingPlayers.map((item) =>
                            <div className={`d-flex align-items-center justify-content-between winnerPlayerPoints px-3 py-2 mb-2${authState?.userDetails?.playerId === item?.playerId ? ' active' : ''}`} key={item.playerId}>
                                <div className="fs-12 fw-800 col-auto p-0 text-blue">{item.sequenceNumber}</div>
                                <div className="fs-11 fw-500 col-9 d-flex align-items-center ">
                                    <div className="d-flex align-items-center">
                                        <div className="mr-2">
                                            {item.profileImageUrl ?
                                                <img className="pointer-user" src={item.profileImageUrl} alt="" /> :
                                                <img className="pointer-user" src="assets/img/pointer-user.png" alt="" />
                                            }
                                        </div>
                                        <div>
                                            <h6 className="fs-12 fw-500 text-black mb-0">{item.nickName} {authState?.userDetails?.playerId === item?.playerId && <span>(You)</span>}</h6>
                                            {/* <div className="id-box m-0">#{item.levelTitle ?? 1}</div> */}
                                            <div className='badges-box'>
                                                {item?.levelIconImageUrl && <img className="badges" src={item?.levelIconImageUrl} alt="levelIcon" />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="fs-12 fw-700 col-2 text-right text-black">{item.totalWinningAmount}</span>
                            </div>)}
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}

export default Leaderboard