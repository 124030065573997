import { callGetApi } from 'api/ApiCaller';
import { GET_LEVEL_DETAILS_GET } from 'api/ApiConst';
import { NO_ERROR } from 'api/constants';
import { GlobalContext } from 'App';
import React, { useContext, useEffect, useState } from 'react'
import { SET_PROGRESS_DETAILS } from 'reducer/actions/utilityActions';

const LevelProgressBar = () => {
    // const [progressBarDetails, setProgressBarDetails] = useState(null);
    const { utilityState, dispatchUtility } = useContext(GlobalContext);
    const { progressDetails } = utilityState;

    useEffect(() => {
        getProgressBarDetails();
    }, [])

    const getProgressBarDetails = () => {
        callGetApi(GET_LEVEL_DETAILS_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                let res = response.responsePacket;
                dispatchUtility({ type: SET_PROGRESS_DETAILS, payload: res });
            }
        })
    }

    return (
        <div className="w-100 px-5 mt-1">
            <div className="d-flex justify-content-between mb-1">
                <h6 className="mb-0 fs-12 text-white">{progressDetails?.levelTitle}</h6>
                <h6 className="mb-0 fs-12 text-white">{progressDetails?.currentLevelPointTo !== -1 && progressDetails?.nextLevelTitle}</h6>
                {/* <h6 className="mb-0 fs-12 text-white">{progressDetails?.currentLevelPointTo === -1 ? 'Max' : progressDetails?.nextLevelTitle}</h6> */}
            </div>
            <div className=' position-relative d-flex align-items-center'>
                <div className="progress w-100" style={{ maxWidth: "100%" }}>
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${progressDetails?.currentLevelPointTo === -1 ? 100 : ((progressDetails?.rightAnswerCount / (progressDetails?.currentLevelPointTo + 1)) * 100)}%` }}
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    ></div>
                </div>
                <div className='d-flex justify-content-between position-absolute' style={{ left: "-25px", right: "-25px" }}>
                    <span className='level-box'>
                        {progressDetails?.levelIconImageUrl && <img className="badges" src={progressDetails?.levelIconImageUrl} alt="levelIcon" />}
                    </span>
                    <span className='level-box'>
                        {progressDetails?.nextLevelIconImageUrl ? <img className="badges" src={progressDetails?.nextLevelIconImageUrl} alt="levelIcon" /> : <span className="mb-0 ml-2 fs-12 text-white">Max</span>}
                    </span>
                </div>
            </div>
            <div className='fs-12 text-white'>{Math.floor(progressDetails?.currentLevelPointTo === -1 ? 100 : ((progressDetails?.rightAnswerCount / (progressDetails?.currentLevelPointTo + 1)) * 100))}% completed</div>
        </div>
    )
}

export default LevelProgressBar