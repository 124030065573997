import React from 'react'
import { useLocation, useParams } from 'react-router-dom';

const StaticPage = () => {
    const params = useParams();
    const {type} = params;
    console.log(type)
  return (
    <div>{type}</div>
  )
}

export default StaticPage;