// // Spin It Up Staging Server
export const HTTPS = process.env.REACT_APP_HTTPS;
export const WSS = process.env.REACT_APP_WSS;
export const HOST = process.env.REACT_APP_HOST;
export const PORT = process.env.REACT_APP_PORT;
export const SERVICE = process.env.REACT_APP_SERVICE;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
// // http://ludo.spinitup.games:8006
export const LGPS_HTTPS = process.env.REACT_APP_LGPS_HTTPS;
export const LGPS_WSS = process.env.REACT_APP_LGPS_WSS;
export const LGPS_DOMAIN = process.env.REACT_APP_LGPS_DOMAIN;
export const LGPS_PORT = process.env.REACT_APP_LGPS_PORT;
export const LGPS_SERVICE = process.env.REACT_APP_LGPS_SERVICE;
// // Rabbit MQ
export const LGPS_RMQ_HOST = process.env.REACT_APP_LGPS_RMQ_HOST;
export const LGPS_RMQ_AMQP_PORT = process.env.REACT_APP_LGPS_RMQ_AMQP_PORT;
export const LGPS_RMQ_WEB_PORT = process.env.REACT_APP_LGPS_RMQ_WEB_PORT;
export const LGPS_RMQ_VIRTUAL_HOST = process.env.REACT_APP_LGPS_RMQ_VIRTUAL_HOST;
export const LGPS_RMQ_USERNAME = process.env.REACT_APP_LGPS_RMQ_USERNAME;
export const LGPS_RMQ_PASSWORD = process.env.REACT_APP_LGPS_RMQ_PASSWORD;
export const LGPS_RMQ_EXCHANGE = process.env.REACT_APP_LGPS_RMQ_EXCHANGE;

export const GAME_PLAY_SERVICE_URL = process.env.REACT_APP_GAME_PLAY_SERVICE_URL

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const CERTIFICATE_ENABLED = process.env.REACT_APP_CERTIFICATE_ENABLE;
export const BASE_URL = `${HTTPS}://${HOST}:${PORT}`;

// Baser url for API
export const BASE_URL_API = BASE_URL + "/api";
export const BASE_URL_V2 = BASE_URL_API + "/v2";
export const BASE_URL_V1 = BASE_URL_API + "/v1";
export const BASE_URL_V3 = BASE_URL_API + "/v3";

export const Get_StaticPages_List_GET = BASE_URL_V1 + "/{pageType}";
export const GET_LEADERBOARD_LIST = BASE_URL_V1 + "/getLeaderBoard";

export const Generate_Payment_Transaction_POST = BASE_URL_V2 + "/sambhav-pay/generatePaymentTransaction";
export const Payment_Gateway_Controller_POST = BASE_URL_API + "/player/generatePayment";
export const Pay_By_UPI_POST = BASE_URL_V2 + "/sambhav-pay/payByUPI/{paymentTransactionRefId}";
export const Pay_By_Card_POST = BASE_URL_V2 + "/payByCard/{paymentTransactionRefId}";

// Login & Register
export const Generate_Registration_Otp_POST = BASE_URL_V2 + "/generateRegistrationOtp";
export const Verify_Register_Otp_POST = BASE_URL_V2 + "/registerPlayer";
export const Generate_Login_Otp_POST = BASE_URL_V2 + "/generateLoginOtp";
export const Verify_Login_Otp_POST = BASE_URL_V2 + "/loginPlayer";
export const Generate_Login_Otp_POST_V3 = BASE_URL_V3 + "/generateLoginOtp";
export const Verify_Login_Otp_POST_V3 = BASE_URL_V3 + "/loginPlayer";
export const LOGIN_WITH_FIREBASE_POST_V3 = BASE_URL_V3 + "/loginPlayerByFireBase";

// Profile
export const Profile_Detail_GET = BASE_URL_V2 + "/profileDetail";
export const Logout_POST = BASE_URL_V2 + "/logout";
export const Update_Nick_Name_POST = BASE_URL_V2 + "/updateNickName";
export const Update_Avatar_Image_POST = BASE_URL_V2 + "/updateAvatarImage";
export const Upload_File_POST = BASE_URL + "/uploadFile/inBase64";
export const Avatar_List_GET = BASE_URL_V1 + "/avatarList";
export const Upload_PAN_In_Multipart_POST = BASE_URL_V2 + "/uploadPanCard";
export const Upload_Aadhar_In_Multipart_POST = BASE_URL_V2 + "/uploadAadharCard";

// Wallet
export const Wallet_Balance_GET = BASE_URL_V1 + "/walletBalanceWithdrawableBalance";
export const Wallet_Transaction_History_POST = BASE_URL_V2 + "/walletTransactionHistory";
export const Wallet_Withdraw_Request_POST = BASE_URL_V1 + "/withdrawMoneyRequest";
export const All_Transaction_List_POST = BASE_URL_V1 + "/allTransactionsList";
export const Wallet_Withdraw_Request_List_POST = BASE_URL_V1 + "/withdrawMoneyRequestList";
export const Withdraw_Money_Request_Details_Get = BASE_URL_V1 + "/withdrawMoneyRequestDetails/{withdrawRequestId}";
export const Get_LastKnownWithdrawAccountTypeDetail_GET = BASE_URL_V2 + "/getLastKnownWithdrawAccountTypeDetail/{accountType}";

// KYC
export const Upload_KYC_Document_POST = BASE_URL_V2 + "/uploadKYCDocument";
export const Get_KYC_Document_List_POST = BASE_URL_V2 + "/getKYCDocumentList";

// Dashboard
export const Template_List_Get = BASE_URL_V2 + "/ludo/templateList";

export const Add_Cash_Transaction_List_POST = BASE_URL_V2 + "/allPaymentTransactionsList";
export const Get_Payment_Transaction_GET = BASE_URL_V2 + "/getPaymentTransaction/{paymentTransactionRefId}";
export const Check_Payment_Status_GET = BASE_URL_V1 + "/paymentTransactionStatus/{paymentTransactionRefId}";

// Player Game Ludo

export const Get_CanIPlay_GET = BASE_URL_V2 + "/ludo/canIPlay";
export const Get_MyRunningGameList_GET = BASE_URL_V2 + "/ludo/getMyRunningGameList";

// Static page
export const Get_Games_Rule_GET = BASE_URL_V1 + "/{pageType}";

// Get Store Product List
export const Get_StoreProductList_GET = BASE_URL_V1 + "/storeProductList";

// Get Banner List
const Banner_List_URL = BASE_URL_V1 + "/bannerList";
export const Get_Coupon_Banner_List = Banner_List_URL + "/{bannerPosition}";

// Get Game Session Data details
export const Get_Game_Session_Data_POST = BASE_URL_V1 + "/ludo/gameSessionData";

// Refresh Wallet Button
export const Refresh_Wallet_BALANCE_GET = BASE_URL_V1 + "/refreshBalance";


// Level Details
export const GET_LEVEL_DETAILS_GET = BASE_URL_V3 + '/getLevelDetails';
export const GET_LEVEL_LIST_GET = BASE_URL_V3 + '/getLevelList';