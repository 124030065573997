import React, { useContext, useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "App";
import { ACCOUNT_ } from "constants/staticConstants";
import { Chain, EditIcon, NotificationIcon, SettingIcon } from "./common/SvgIcon";
import FooterMenu from "./FooterMenu";
import { shareVia } from "utils/userUtils";
import { callPostApi } from "api/ApiCaller";
import { Get_Game_Session_Data_POST } from "api/ApiConst";
import { convertAmount } from "utils/CurrencyUtils";

const gameSessionRecordObj = {
    totalEarning: 0,
    totalGameWon: 0,
    winRate: 0,
    totalGamePlayed: 0,
    twoPlayerGameWin: 0,
    fourPlayerGameWin: 0
}

export const Profile = () => {
    const { authState, utilityState, dispatchUtility } = useContext(GlobalContext);

    const [gameSessionRecord, setGameSessionRecord] = useState(gameSessionRecordObj)

    const navigate = useNavigate();

    useEffect(() => {
        getGameSessionData();
    }, [])


    const getGameSessionData = () => {
        callPostApi(Get_Game_Session_Data_POST, {}, {}, (response) => {
            if (response.errorCode === 0) {
                const res = response.responsePacket;
                if (!res) {
                    return;
                }
                if (res.length <= 0) {
                    return;
                }
                const totalGamePlayed = res.gamePlayed.reduce(
                    (acc, cur) => acc + cur.gamePlayedCount, 0)
                const totalBets = res.gamePlayed.reduce(
                    (acc, cur) => acc + cur.betsTotalAmount, 0)
                const totalGameWon = res.gamePlayed.reduce(
                    (acc, cur) => acc + cur.gameWonCount, 0)
                const totalBetWins = res.gamePlayed.reduce(
                    (acc, cur) => acc + cur.gameWinningsAmount, 0)

                let totalTwoPlayerGamePlayed = 0;
                let totalFourPlayerGamePlayed = 0;
                for (let i = 0; i < res.gamePlayed.length; i++) {
                    if (res.gamePlayed[i].playerCount === 2) {
                        totalTwoPlayerGamePlayed += res.gamePlayed[i].gameWonCount;
                    }
                    if (res.gamePlayed[i].playerCount === 4) {
                        totalFourPlayerGamePlayed += res.gamePlayed[i].gameWonCount;
                    }
                }

                setGameSessionRecord((prev) => ({
                    ...prev,
                    totalGamePlayed: totalGamePlayed,
                    totalEarning: totalBetWins,
                    totalGameWon: totalGameWon,
                    winRate: (totalGameWon / totalGamePlayed) * 100 || 0,
                    twoPlayerGameWin: totalTwoPlayerGamePlayed || 0,
                    fourPlayerGameWin: totalFourPlayerGamePlayed || 0
                }))
            }
        }, (err) => {
            console.error(err.message);
        })
    }

    const message = `अरे Bunzzi Ludo join नहीं किया? तो अभी करो और खेलो मेरे जैसे free cash games, बड़े tournaments और पहले deposit पर पाओ 20% तक के reward 😍🎲 \n 🎁 तो काल करे सो आज कर, आज करे सो अब, \n Download करो  Bunzzi Ludo और जीतो असली money हर वक़्त 🎁 \n 📩 यह exclusive invite use करो और 10 लाख तक के इनाम जीतो \n Download Now👇 \n Referral Code :- ${authState?.userDetails?.referralCode} \n\n https://www.bunzzi.in`
    return (
        <Wrapper>
            <div>
                <div className="dashboard-header">
                    <div className="container-fluid px-2">
                        <div className="d-flex justify-content-between">
                            <div className="col-6 cursor-pointer text-left p-0 " onClick={() => navigate("/account")}>
                                <div className="d-flex align-items-center ">
                                    <div className="user-profile mr-2">
                                        <img className="rounded-circle" src={authState.userDetails?.profileImgUrl ? authState.userDetails?.profileImgUrl : "assets/img/avtaar-user.png"} />
                                        {authState?.userDetails?.levelIconImageUrl && <img className="badges" src={authState?.userDetails?.levelIconImageUrl} alt="levelIcon" />}
                                    </div>
                                    <div className="user-content">
                                        <div className="text-yellow font-size-14 font-weight-600 mb-0">{authState.userDetails?.nickName}</div>
                                        <p className="mb-0 font-weight-500">{ACCOUNT_}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 p-0 text-right pt-1">
                                <span className="cursor-pointer mr-2" onClick={() => navigate("/account")}>
                                    <EditIcon />
                                </span>
                                <span className="cursor-pointer mr-2" onClick={() => navigate("/setting")}>
                                    <SettingIcon />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-main-content mt-2">
                    <div className="container-fluid">
                        <div className="yellowCard mb-3">
                            <div className="innerCard py-3">
                                <h5 className="text-black font-weight-600 text-center">User Profile</h5>
                                <div className="profileContent">
                                    <div className="amountBox">
                                        <div className="d-flex">
                                            <div className="col-4 p-0 text-center vr">
                                                <h6 className="text-white font-weight-600 font-size-12">Total Winnings</h6>
                                                <h5 className="font-weight-700 mb-0 font-size-14" style={{ color: "#FFF200" }}>
                                                    {convertAmount(gameSessionRecord.totalEarning)}
                                                </h5>
                                            </div>
                                            <div className="col-4 p-0 text-center vr">
                                                <h6 className="text-white font-weight-600 font-size-12">Game Won</h6>
                                                <h5 className="font-weight-700 mb-0 font-size-14" style={{ color: "#FFF200" }}>
                                                    {gameSessionRecord.totalGameWon}
                                                </h5>
                                            </div>
                                            <div className="col-4 p-0 text-center">
                                                <h6 className="text-white font-weight-600 font-size-12">Win Rate</h6>
                                                <h5 className="font-weight-700 mb-0 font-size-14" style={{ color: "#FFF200" }}>
                                                    {gameSessionRecord.winRate.toFixed(2)}%
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div className="GamePlayedAmount">
                                            <div className=" d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h6 className="text-white font-weight-600 font-size-12 mb-1">Total Game Played</h6>
                                                    <h5 className="text-white font-weight-700 font-size-14 mb-0">{gameSessionRecord.totalGamePlayed}</h5>
                                                </div>
                                                <div>
                                                    <h6 className="text-white font-weight-600 font-size-12 mb-1">Player Level</h6>
                                                    <h5 className="text-white font-weight-700 font-size-14 mb-0 text-right">{authState.userDetails.levelTitle}</h5>
                                                </div>
                                            </div>
                                            <div className=" mt-2">
                                                <div className="gamePlayerCard">
                                                    <div className="d-flex align-items-center justify-content-between ">
                                                        <div>
                                                            <h6 className="text-black font-weight-600 font-size-16 mb-1">Ludo</h6>
                                                            <h5 className="text-black font-weight-700 font-size-14 mb-0">{gameSessionRecord.totalGamePlayed}</h5>
                                                        </div>
                                                        <div>
                                                            <img src="assets/img/ludoDice.png" alt="ludoDice" style={{ width: "50px" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" d-flex mt-2" style={{ gap: "5px" }}>
                                        <div className="PlayerBox">
                                            <div className="innerBox">
                                                <h5 className="text-black font-weight-700 font-size-16 mb-1">{gameSessionRecord.twoPlayerGameWin}</h5>
                                                <h6 className="text-black font-weight-600 font-size-14 mb-0">2 Player win</h6>
                                            </div>
                                            {/* <button className="linkBtn mt-2">View</button> */}
                                        </div>
                                        <div className="PlayerBox">
                                            <div className="innerBox">
                                                <h5 className="text-black font-weight-700 font-size-16 mb-1">{gameSessionRecord.fourPlayerGameWin}</h5>
                                                <h6 className="text-black font-weight-600 font-size-14 mb-0">4 Player win</h6>
                                            </div>
                                            {/* <button className="linkBtn mt-2">View</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMenu />
                </div>
            </div>
        </Wrapper>
    );
};
