import { GlobalContext } from "App";
import html2canvas from "html2canvas";
import React, { useContext, useEffect } from "react";

const Certificate = () => {

    const { authState } = useContext(GlobalContext);
    // console.log(authState.userDetails.fullName)
    useEffect(() => {
            downloadImage();
    }, [])


    function downloadImage() {
        const content = document.getElementById("content");
        html2canvas(content, {
            backgroundColor: "#ffffff",
            useCORS: true,
        }).then(function (canvas) {
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            let date = new Date();
            let dateTimeStr = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + "-" +
                date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds();
            link.download = "Certificate_" + dateTimeStr + ".png";
            link.click();
        }).catch((error) => {
            console.log(error);
            window.close();
        }).finally(() => {
            window.close();
        });
    }

    return (
        <div className="bg-white">
            <div style={{ width: "990px", margin: "0 auto", padding: "20px" }} id="content" >
                <div
                    style={{
                        textAlign: "center",
                        border: "2px solid rgba(51, 65, 86, 1)",
                        padding: "10px",
                    }}
                >
                    <div
                        style={{
                            padding: "20px",
                            textAlign: "center",
                            border: "1px solid rgba(13, 111, 251, 1)",
                        }}
                    >
                        <div style={{
                            textAlign: "center"
                        }}>
                            <img
                                id="bannerImage"
                                src="/assets/img/banner.png"
                                alt="Banner Image"
                                style={{
                                    width: "25%",
                                    margin: "0 auto",
                                    textAlign: "center"
                                }}
                            />
                        </div>
                        <span style={{ fontSize: "40px", fontWeight: "bold", color: "#000", letterSpacing: "4px", marginTop: "20px", display: "block", marginBottom: "10px" }}>
                            CERTIFICATE OF ACHIEVEMENT
                        </span>

                        <div style={{ fontSize: "18px" }}>
                            This certificate is proudly presented to
                        </div>
                        <br />
                        <span style={{ fontSize: "56px", color: "rgba(0, 82, 155, 1)", fontFamily: "Kaushan Script" }}>
                            {authState?.userDetails?.fullName}
                        </span>
                        <br />
                        <br />
                        <span style={{ fontSize: "18px" }}>
                            for successfully completing <b style={{ color: "rgba(0, 82, 155, 1)" }}>Level {authState?.userDetails?.levelTitle}</b> of Fin-Ludo
                        </span>
                        <br />
                        <br />
                        <span style={{ fontSize: "18px" }}>
                            This certificate is awarded in recognition of your commitment and dedication to
                            <br />
                            advancing your understanding of financial concepts
                        </span>
                        <div style={{ marginTop: "30px" }}>
                            <div style={{ textAlign: "center" }}>
                                <img src="assets/img/sin-img.png" alt="" style={{
                                    width: "178px",
                                    margin: "0 auto",
                                    textAlign: "center"
                                }} />
                            </div>
                            <span style={{ display: "block", fontWeight: "bold", lineHeight: "10px", marginTop: "10px" }}>
                                Anand Radhakrishnan
                            </span>
                            <i>Managing Director</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Certificate;
