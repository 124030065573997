import React, { useContext } from "react";
import * as StaticConstants from "../constants/staticConstants";
import { Link, useLocation } from "react-router-dom";
import { callGetApi } from "../api/ApiCaller";
import { NO_ERROR } from "../api/constants";
import { SET_WALLET_DETAILS } from "../reducer/actions/utilityActions";
import { Wallet_Balance_GET } from "../api/ApiConst";
import { GlobalContext } from "../App";
import { toast } from "react-hot-toast";
import { ERROR_TOAST_ID } from "utils/Constants";

const FooterMenu = () => {
    const { dispatchUtility } = useContext(GlobalContext);
    const location = useLocation();

    const getWalletDetails = () => {
        callGetApi(Wallet_Balance_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                dispatchUtility({ type: SET_WALLET_DETAILS, payload: response.responsePacket });
            } else {
                toast.error(response.message, {
                    id: ERROR_TOAST_ID,
                });
            }
        });
    };

    return (
        <div className="osahan-menu-fotter active-menu fixed-bottom text-center">
            <ul className="list-unstyled mb-0">
                <li className={`${location.pathname === "/" ? "active" : ""}`}>
                    <Link to="/">
                        <span className="span" onClick={getWalletDetails}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="27" viewBox="0 0 29 32" fill="none">
                                <g clipPath="url(#clip0_220_34)">
                                    <path d="M14.5164 8.40771L4.25317 17.8162C4.25317 17.8295 4.25017 17.8491 4.24417 17.8757C4.23829 17.9021 4.23517 17.9213 4.23517 17.9349V27.4621C4.23517 27.806 4.34826 28.1041 4.57439 28.3552C4.80046 28.6064 5.06816 28.7327 5.37756 28.7327H12.2314V21.1108H16.8013V28.733H23.6551C23.9645 28.733 24.2326 28.6069 24.4583 28.3552C24.6844 28.1044 24.7979 27.8061 24.7979 27.4621V17.9349C24.7979 17.8821 24.7914 17.8422 24.7799 17.8162L14.5164 8.40771Z" fill="#003C8E" />
                                    <path d="M28.7063 15.6726L24.7975 12.0602V3.96195C24.7975 3.77681 24.744 3.62456 24.6366 3.50541C24.5301 3.38639 24.3932 3.32688 24.2263 3.32688H20.7993C20.6326 3.32688 20.4958 3.38639 20.3886 3.50541C20.2816 3.62456 20.2281 3.77688 20.2281 3.96195V7.83235L15.873 3.78314C15.4926 3.43908 15.0404 3.26709 14.5167 3.26709C13.9931 3.26709 13.5409 3.43908 13.1602 3.78314L0.32615 15.6726C0.207178 15.7782 0.141972 15.9205 0.129843 16.0992C0.117777 16.2777 0.159352 16.4336 0.254692 16.5658L1.36133 18.0347C1.45667 18.1537 1.58145 18.2265 1.73619 18.2531C1.87904 18.2664 2.02189 18.22 2.16475 18.1142L14.5163 6.66128L26.8681 18.1141C26.9635 18.2065 27.0882 18.2525 27.2429 18.2525H27.2966C27.451 18.2264 27.5756 18.1531 27.6714 18.0344L28.7782 16.5658C28.8733 16.4333 28.915 16.2776 28.9027 16.0989C28.8905 15.9207 28.825 15.7785 28.7063 15.6726Z" fill="#003C8E" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_220_34">
                                        <rect width="28.777" height="32" fill="white" transform="translate(0.127838)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        {StaticConstants.HOME}
                    </Link>
                </li>
                <li className={`${location.pathname === "/wallet" ? "active" : ""}`}>
                    <Link to="/wallet">
                        <span className="span" onClick={getWalletDetails}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 34 33" fill="none">
                                <g clipPath="url(#clip0_220_43)">
                                    <path d="M27.166 24.75C23.7546 24.75 20.9785 21.9739 20.9785 18.5625C20.9785 15.1511 23.7546 12.375 27.166 12.375H30.6035V9.625C30.6035 8.10838 29.3701 6.875 27.8535 6.875H26.4785V4.125C26.4785 2.60837 25.2451 1.375 23.7285 1.375H4.47852C2.43939 1.375 0.753641 2.8655 0.423641 4.81113C0.393391 4.92388 0.353516 5.03387 0.353516 5.15625V26.125C0.353516 28.3993 2.20427 30.25 4.47852 30.25H27.8535C29.3701 30.25 30.6035 29.0166 30.6035 27.5V24.75H27.166ZM3.10352 5.5C3.10352 4.74237 3.71952 4.125 4.47852 4.125H23.7285V6.875H4.47852C3.71952 6.875 3.10352 6.25763 3.10352 5.5Z" fill="#003C8E" />
                                    <path d="M32.3223 14.4375H27.166C24.8918 14.4375 23.041 16.2882 23.041 18.5625C23.041 20.8368 24.8918 22.6875 27.166 22.6875H32.3223C32.8915 22.6875 33.3535 22.2255 33.3535 21.6562V15.4688C33.3535 14.8995 32.8915 14.4375 32.3223 14.4375ZM27.166 19.9375C26.407 19.9375 25.791 19.3215 25.791 18.5625C25.791 17.8035 26.407 17.1875 27.166 17.1875C27.925 17.1875 28.541 17.8035 28.541 18.5625C28.541 19.3215 27.925 19.9375 27.166 19.9375Z" fill="#003C8E" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_220_43">
                                        <rect width="33" height="33" fill="white" transform="translate(0.353516)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        {StaticConstants.WALLET}
                    </Link>
                </li>
                <li className={`${location.pathname === "/account" ? "active" : ""}`}>
                    <Link to="/Leaderboard" onClick={getWalletDetails}>
                        <span className="span">
                            <svg
                                width={25}
                                height={25}
                                viewBox="0 0 33 33"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_346_99)">
                                    <path
                                        d="M23.9385 5.40207C23.8113 5.0113 23.4732 4.72628 23.067 4.66731L19.2009 4.10541L17.4713 0.601605C17.29 0.23332 16.9147 0 16.5036 0C16.0924 0 15.7171 0.23332 15.5358 0.601605L13.8062 4.10547L9.93943 4.66737C9.53318 4.72641 9.19512 5.01136 9.06796 5.40214C8.94079 5.79292 9.04688 6.22198 9.34137 6.50873L12.14 9.236L11.4787 13.0867C11.4091 13.4918 11.5757 13.9012 11.9081 14.1427C12.2413 14.3852 12.682 14.415 13.0453 14.2249L16.5038 12.4068L19.9617 14.2249C20.3334 14.4199 20.7723 14.3797 21.0988 14.1427C21.4312 13.9013 21.5978 13.4919 21.5283 13.0871L20.8676 9.236L23.6656 6.50873C23.9597 6.22198 24.065 5.79285 23.9385 5.40207Z"
                                        fill="#003C8E"
                                    />
                                    <path
                                        d="M20.818 16.4473H12.1819C11.5858 16.4473 11.1024 16.9306 11.1024 17.5268V32.9998H21.8975V17.5268C21.8975 16.9306 21.4142 16.4473 20.818 16.4473Z"
                                        fill="#003C8E"
                                    />
                                    <path
                                        d="M1.3869 20.7656C0.790707 20.7656 0.307373 21.249 0.307373 21.8451V32.1466C0.307373 32.618 0.689516 33.0001 1.16086 33.0001H8.94351V20.7656H1.3869Z"
                                        fill="#003C8E"
                                    />
                                    <path
                                        d="M31.6132 25.0835H24.0566V33H31.8393C32.3107 33 32.6928 32.6178 32.6928 32.1465V26.163C32.6927 25.5668 32.2094 25.0835 31.6132 25.0835Z"
                                        fill="#003C8E"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_346_99">
                                        <rect width={33} height={33} fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        Leaderboard
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default FooterMenu;
