import { callPostApi } from "api/ApiCaller";
import { Generate_Payment_Transaction_POST, Pay_By_UPI_POST } from "api/ApiConst";
import { MULTIPLE_ERRORS, NO_ERROR } from "api/constants";
import Wrapper from "components/Wrapper";
import CustomHeader from "components/common/CustomHeader";
import Loader from "components/common/Loader";
import ModalBox from "components/common/Modal";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { ERROR_TOAST_ID } from "utils/Constants";

const PaymentMethod = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [UPIUrl, setUPIUrl] = useState(null);
    const [currentTransactionDetail, setCurrentTransactionDetail] = useState(null);
    // // // TODO: Payment method screen implementation
    const [showWebModal, setShowWebModal] = useState(false);
    const [showPendingPaymentModal, setShowPendingPaymentModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showCouponCodeModal, setShowCouponCodeModal] = useState(false);

    const handlePayByOther = (upiUrl) => {
        if (upiUrl !== undefined) {
            window.sendMessageToVuplex("UpdateShowUri", upiUrl);
        } else {
            window.sendMessageToVuplex("UpdateShowUri", UPIUrl);
        }
        setShowPendingPaymentModal(false);
        setShowWebModal(true);
        // setLoading(null);
    };

    const handlePayByUPI = (paymentTransactionRefId) => {
        setLoading(true);
        callPostApi(Pay_By_UPI_POST.replaceAll("{paymentTransactionRefId}", paymentTransactionRefId), {}, {}, (response) => {
            // callPostApi(Pay_By_UPI_POST.replaceAll("{paymentTransactionRefId}", currentTransactionDetail.paymentTransactionRefId), {}, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                // window.sendMessageToVuplex("UpdateShowUri", response.responsePacket?.intentUrl);
                // // // To open UPI dialog.
                // setLoading("UPIUrl");
                // setUPIUrl(response.responsePacket?.intentUrl);
                // setShowPendingPaymentModal(true);
                // // // // setShowWebModal(true);
                // // To open direct url.
                // setLoading(null);
                setUPIUrl(response.responsePacket?.intentUrl);
                handlePayByOther(response.responsePacket?.intentUrl);
            } else {
                toast.error(response.message, { id: ERROR_TOAST_ID });
                // setLoading(null);
            }
            setLoading(false);
        });
    };

    const handleAddCash = () => {
        if (location?.state?.amount === "" || location?.state?.amount <= 0) {
            toast("Please Enter some amount", { id: "enterAmount" });
            return;
        }
        const rObj = {
            amount: location?.state?.amount,
        };
        // setLoading("addCash");
        callPostApi(Generate_Payment_Transaction_POST, rObj, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                setCurrentTransactionDetail(response.responsePacket);
                handlePayByUPI(response.responsePacket.paymentTransactionRefId);
                // handlePayByCard(response.responsePacket.paymentTransactionRefId);
                // setLoading(null);
                // // TO Open UPI Dialog
                // setShowPendingPaymentModal(true);
            } else if (response.errorCode === MULTIPLE_ERRORS) {
                toast.error(
                    response.responsePacket.map((data) => <div>{data}</div>),
                    {
                        id: ERROR_TOAST_ID,
                    }
                );
                // setLoading(null);
            } else {
                toast.error(response.message, {
                    id: ERROR_TOAST_ID,
                });
                // setLoading(null);
            }
        });
    };

    return (
        <Wrapper>
            <div>
                <CustomHeader title={"Payment Method"} />
                <div className="p-3">
                    <div className="AmountCard">
                        <div className="innerBox">
                            <h6 className="font-size-16 font-weight-600" style={{ color: "#6F571D" }}>
                                Deposit Amount
                            </h6>
                            <h4 className="text-black font-size-24 font-weight-700">₹ {Intl.NumberFormat("en-IN").format(location?.state?.amount)}</h4>
                        </div>
                    </div>
                    <div className="customHr my-4"></div>
                    <div className="AmountCard mb-3">
                        <div className="innerBox">
                            <h6 className="font-size-16 font-weight-600" style={{ color: "#6F571D" }}>
                                Coupon Code
                            </h6>
                            <div className="d-flex align-items-center " style={{ gap: "8px" }}>
                                <div className="w-100 p-0">
                                    <div className="customize-input py-2 rounded">
                                        <p className="mb-0 text-black font-size-14 font-weight-600">xyz</p>
                                    </div>
                                </div>
                                <div className="p-0">
                                    <button className="btnRed text-white font-size-16 font-weight-600 py-1 px-3 w-100 ">Remove</button>
                                </div>
                            </div>

                            <div className="text-center">
                                <button className="btn custom-yellow-btn text-black font-size-14 py-2" onClick={() => setShowCouponCodeModal(true)}>
                                    Apply Coupon code
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="yellowCard mb-3">
                        <div className="innerCard py-3">
                            <div className="d-flex align-items-center justify-content-between" onClick={handleAddCash}>
                                <div className="d-flex align-items-center">
                                    <span className="iconCard">
                                        <img src="assets/img/walletIcon.png" alt="walletIcon" />
                                    </span>
                                    <div className="ml-2">
                                        <p className="mb-0 text-white font-weight-600">Deposit</p>
                                    </div>
                                </div>
                                <div>
                                    <img src="assets/img/rightIcon.png" alt="rightIcon" style={{ width: "25px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showWebModal && (
                <ModalBox show={showWebModal} toggleModel={() => setShowWebModal(false)} modalClass={"sliderBlockModal fullScreenModal"}>
                    <div className="modal-content bg-yellowGradient border-none">
                        <div className="modal-body p-0">
                            <button type="button" className="btn-close" onClick={() => setShowWebModal(false)}>
                                <span className="cursor-pointer">
                                    <img src="assets/img/modal-close.png" alt="" style={{ width: "20px" }} />
                                </span>
                            </button>

                            <div>
                                <iframe src={currentTransactionDetail.callBackUrl} frameBorder="0" width={"100%"} style={{ height: "60vh", marginBottom: "-8px" }}></iframe>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            )}
            <ModalBox show={showCouponCodeModal} toggleModel={() => setShowCouponCodeModal(false)} modalClass={"sliderBlockModal "}>
                <div className="modal-content bg-yellowGradient border-none">
                    <div className="modal-body p-0">
                        <div className=" modal-body-header">
                            <button type="button" className="btn-close" onClick={() => setShowCouponCodeModal(false)}>
                                <span className="cursor-pointer">
                                    <img src="assets/img/modal-close.png" alt="" style={{ width: "40px" }} />
                                </span>
                            </button>
                            <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                Available Coupon list
                            </h5>
                        </div>
                        <div className="p-2 staticPageText">
                            <div className="yellowCard mb-1 rounded">
                                <div className="innerCard  rounded px-2 py-2 history_list">
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <div className="text-white font-size-12">
                                            <span>Coupon Code</span>
                                            <span className="text-black ml-2">TXN-0000050879</span>
                                        </div>
                                        <div>
                                            <button className="WithdrawBtn">
                                                <span className=" font-size-14 py-1">Apply</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="text-white font-size-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            {loading && <Loader />}
        </Wrapper>
    );
};

export default PaymentMethod;
