import React, { useContext, useEffect, useState } from "react";
import * as StaticConstants from "../constants/staticConstants";
import { toast } from "react-hot-toast";
import { Get_StaticPages_List_GET, LOGIN_WITH_FIREBASE_POST_V3 } from "../api/ApiConst";
import { callGetApiWithoutAuth, callPostApiWithoutAuth } from "../api/ApiCaller";
import { NO_ERROR } from "../api/constants";
import { GlobalContext } from "../App";
import { LOGIN } from "../reducer/actions/userActions";
import ModalBox from "./common/Modal";
import Loader from "./common/Loader";
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { auth, facebookProvider, googleProvider, twitterProvider } from "firebase";
import { ERROR_TOAST_ID } from "utils/Constants";

const RegisterLogin = () => {
    const { dispatchAuth } = useContext(GlobalContext);
    const [showTermsConditionsModal, setShowTermsConditionsModal] = useState(false);
    const [termsAndCondition, setTermsAndCondition] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        callGetApiWithoutAuth(Get_StaticPages_List_GET.replaceAll("{pageType}", "TermsAndConditions"), (response) => {
            if (response.errorCode === NO_ERROR) {
                setTermsAndCondition(response.responsePacket.pageHtmlContent);
            } else {
                toast.error(response.message, {
                    id: ERROR_TOAST_ID,
                });
            }
        });
        setTimeout(() => {
            window.navigateToDashboard();
        }, 1500);
    }, []);

    // after OTP verified successfully this will navigate the user to dashboard and will set the user details
    const handleSuccessNavigateToDashboard = (userDetails) => {
        dispatchAuth({ type: LOGIN, payload: userDetails });
    };

    window.navigateToDashboard = (userDetails) => {
        if (userDetails !== null && userDetails !== undefined && userDetails !== "undefined") {
            handleSuccessNavigateToDashboard(userDetails);
        } else {
            setLoading(false);
        }
    };

    const handleGoogleLogin = () => {
        signInWithPopup(auth, googleProvider)
            .then((result) => {
                const userData = {
                    idToken: result._tokenResponse.idToken,
                };
                return userData;

            })
            .then((userData) => {
                callPostApiWithoutAuth(LOGIN_WITH_FIREBASE_POST_V3, userData, (response) => {
                    if (response.errorCode === NO_ERROR) {
                        toast.success(response.message, { id: "verifyOtp" });
                        handleSuccessNavigateToDashboard(response.responsePacket);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.error(response.message, { id: ERROR_TOAST_ID });
                    }
                });
            }).catch((error) => {
                console.error('Error during Google login or registration:', error);
                toast.error(error.response?.data?.error || 'An error occurred during login.', {id: ERROR_TOAST_ID});
            });
    }
    
    const handleTwitterLogin = () => {
        signInWithPopup(auth, twitterProvider)
            .then((result) => {
                const userData = {
                    idToken: result._tokenResponse.idToken,
                };
                return userData;

            })
            .then((userData) => {
                console.log(userData);
                callPostApiWithoutAuth(LOGIN_WITH_FIREBASE_POST_V3, userData, (response) => {
                    if (response.errorCode === NO_ERROR) {
                        toast.success(response.message, { id: "verifyOtp" });
                        handleSuccessNavigateToDashboard(response.responsePacket);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.error(response.message, { id: ERROR_TOAST_ID });
                    }
                });
            }).catch((error) => {
                console.error('Error during Google login or registration:', error);
                toast.error(error.response?.data?.error || 'An error occurred during login.', {id: ERROR_TOAST_ID});
            });
    }
    
    const handleFacebookLogin = () => {
        signInWithPopup(auth, facebookProvider)
            .then((result) => {
                const userData = {
                    idToken: result._tokenResponse.idToken,
                };
                return userData;

            })
            .then((userData) => {
                console.log(userData);
                callPostApiWithoutAuth(LOGIN_WITH_FIREBASE_POST_V3, userData, (response) => {
                    if (response.errorCode === NO_ERROR) {
                        toast.success(response.message, { id: "verifyOtp" });
                        handleSuccessNavigateToDashboard(response.responsePacket);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.error(response.message, { id: ERROR_TOAST_ID });
                    }
                });
            }).catch((error) => {
                console.error('Error during Google login or registration:', error);
                toast.error(error.response?.data?.error || 'An error occurred during login.', {id: ERROR_TOAST_ID});
            });
    }

    return (
        <>
            <div className="wrapper-main wrapper-bg">
                <div className="d-flex w-100" style={{ flexDirection: "column" }}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <div>
                                <img src="assets/img/banner_001.png" alt="homeSlider1" />
                            </div>
                            <div className=" d-flex align-items-center" style={{ flex: "1" }}>
                                <div className="container padding-content">
                                    <div className="text-center">
                                        <img src="assets/img/logo.png" style={{ width: "60%" }} />
                                    </div>
                                    <div className="text-center loginContent mt-4">
                                        <div className="py-2">
                                            <h3 className="mb-0 font-size-24 font-weight-700 text-black">{StaticConstants.LOGIN_SIGNUP}</h3>
                                        </div>
                                        <div className="innerBox d-flex flex-column gap-2">
                                            <button className="btn custom-yellow-btn w-100" onClick={() => handleGoogleLogin()}>
                                                Login With Google
                                            </button>
                                            <button className="btn custom-yellow-btn w-100" onClick={() => handleTwitterLogin()}>
                                                Login With X
                                            </button>
                                            {/*<button className="btn custom-yellow-btn w-100" onClick={() => handleFacebookLogin()}>
                                                Login With Facebook
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {showTermsConditionsModal && (
                <ModalBox show={showTermsConditionsModal} toggleModel={() => setShowTermsConditionsModal(false)} modalClass={" termsAndConditionsModal"}>
                    <div className="modal-content bg-yellowGradient border-none">
                        <div className="modal-body p-0">
                            <div className=" modal-body-header">
                                <button type="button" className="btn-close" style={{ right: "15px" }} onClick={() => setShowTermsConditionsModal(false)}>
                                    <span className="cursor-pointer">
                                        <img src="assets/img/modal-close.png" alt="" style={{ width: "40px" }} />
                                    </span>
                                </button>
                                <h5 className="modal-title text-black font-size-18 font-weight-600 px-2 text-center" id="exampleModalLongTitle">
                                    {StaticConstants.TERMS_AND_CONDITIONS}
                                </h5>
                            </div>
                            <div className="staticPageText p-3">
                                <div dangerouslySetInnerHTML={{ __html: termsAndCondition }} />
                            </div>
                        </div>
                    </div>
                </ModalBox>
            )}
        </>
    );
};

export default RegisterLogin;
