import { callPostApi } from "api/ApiCaller";
import { Get_KYC_Document_List_POST, Upload_File_POST, Upload_KYC_Document_POST } from "api/ApiConst";
import Wrapper from "components/Wrapper";
import CustomHeader from "components/common/CustomHeader";
import {
    AADHAR_Back,
    AADHAR_CARD,
    AADHAR_Front,
    PAN_CARD,
    PAN_CARD_,
} from "constants/staticConstants";
import React, {useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";
import { ERROR_TOAST_ID } from "utils/Constants";

export const UploadCard = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const fileRefAadharFront = useRef();
    const fileRefAadharBack = useRef();
    const fileRefPanCard = useRef();

    const [cardType, setCardType] = useState(location?.state?.type);
    const [selectedImageType, setSelectedImageType] = useState("");
    const [aadhaarFrontImage, setAadhaarFrontImage] = useState("");
    const [aadhaarFrontData, setAadhaarFrontData] = useState(null);
    const [aadhaarBackImage, setAadhaarBackImage] = useState("");
    const [aadhaarBackData, setAadhaarBackData] = useState(null);
    const [panCardImage, setPanCardImage] = useState("");
    const [panCardImageData, setPanCardImageData] = useState(null);
    const [docNumber, setDocNumber] = useState("");

    useEffect(() => {
        getImages();
    }, [])

    const getImages = () => {
        let documentType = (cardType === "Pan Card") ? "PanCard" : "AadharCard";
        callPostApi(Get_KYC_Document_List_POST, {}, {}, (response) => {
            if (response.errorCode === 0) {
                const res = response.responsePacket;
                const _res = res.find((item) => item.documentType === documentType);  
                setDocNumber(_res.documentNumber);
                if (cardType === PAN_CARD) {
                    setPanCardImage(_res.frontImageUrl);
                    setPanCardImageData(_res.frontImageUrl);
                }
                if (cardType === AADHAR_CARD) {
                    setAadhaarFrontImage(_res.frontImageUrl);
                    setAadhaarBackImage(_res.backImageUrl);    
                    setAadhaarFrontData(_res.frontImageUrl);
                    setAadhaarBackData(_res.backImageUrl);    
                }
            } else {
                console.error("Catch Error :: ", response.message)
            }
        })
    }
    

    const handleImageSelection = (fileEvent) => {
        const {files} = fileEvent.target;
        var _validFileExtensions = [".jpg", ".jpeg", ".gif", ".png"];
        if (files && files[0]) {
            var reader = new FileReader();
            reader.onload = async function (event) {
                const {result} = event.target;
                const imageFileData = {
                    fileName: files[0].name,
                    fileBase64: result,
                };
                if ((files[0].size / 1000) > 2048) {
                    toast.error("Image_size_cannot_exceed_2048_KBs", {id: ERROR_TOAST_ID});
                }
                // Validating image extensions
                if (imageFileData.fileName.length > 0) {
                    var blnValid = false;
                    for (var j = 0; j < _validFileExtensions.length; j++) {
                        var sCurExtension = _validFileExtensions[j];
                        if (
                            imageFileData.fileName
                                .substr(
                                    imageFileData.fileName.length -
                                        sCurExtension.length,
                                    sCurExtension.length
                                )
                                .toLowerCase() == sCurExtension.toLowerCase()
                        ) {
                            blnValid = true;
                        }
                    }

                    if (!blnValid) {
                        toast.error(
                            "Sorry, " +
                                imageFileData.fileName +
                                " is invalid. Allowed extensions are: " +
                                _validFileExtensions.join(", "),
                            {id: ERROR_TOAST_ID}
                        );
                        return false;
                    }
                }

                let imageRefId = await callImageUploader(result, files[0].name);

                // // -------------
                if (selectedImageType === AADHAR_Front) {
                    fileRefAadharFront.current.setAttribute("src", result);
                    setAadhaarFrontImage(result);
                    setAadhaarFrontData(imageRefId);
                } else if (selectedImageType === AADHAR_Back) {
                    fileRefAadharBack.current.setAttribute("src", result);
                    setAadhaarBackImage(result);
                    setAadhaarBackData(imageRefId);
                } else if (selectedImageType === PAN_CARD_) {
                    fileRefPanCard.current.setAttribute("src", result);
                    setPanCardImage(result);
                    setPanCardImageData(imageRefId);
                }
                // handleUploadKYCImage(imageFileData);
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const callImageUploader = async (base64, fileName) => {
        const rObj = {
            fileName: fileName,
            fileBase64: base64
        }
        return callImageUploaderApi(rObj);
    };
    
    const callImageUploaderApi = async (rObj) => {
        return new Promise((resolve, reject) => {
            callPostApi(
                Upload_File_POST,
                rObj,
                {},
                (response) => {
                    if (response.errorCode === 0) {
                        resolve(response.responsePacket);
                        return response.responsePacket;
                    } else {
                        reject(null);
                        toast.error(response.message, {id: ERROR_TOAST_ID});
                    }
                },
                (error) => {
                    reject(null);
                    toast.error(error, {id: ERROR_TOAST_ID});
                    return null;
                }
            );
        });
    };

    const handleImageSelectionRef = (type) => {
        setSelectedImageType(type);
        if (type === AADHAR_Front) {
            handleAadharFrontImageClick();
        }
        if (type === AADHAR_Back) {
            handleAadharBackImageClick();
        }
        if (type === PAN_CARD_) {
            handlePanCardImageClick();
        }
    };

    const handleAadharFrontImageClick = (event) => {
        fileRefAadharFront.current.click();
    };

    const handleAadharBackImageClick = (event) => {
        fileRefAadharBack.current.click();
    };

    const handlePanCardImageClick = (event) => {
        fileRefPanCard.current.click();
    };

    const handleSubmit = () => {
        let payload;
        if (cardType === PAN_CARD) {
            if(docNumber === "") {
                toast.error("Please enter pan card number!!", {id: 'errorToast'});
            }
            if(panCardImageData === "") {
                toast.error("Please upload pan card image!!", {id: 'errorToast'});
            }
            payload = {
                documentNumber: docNumber,
                // documentType: "AadharCard",
                documentType: "PanCard",
                frontImageUrl: panCardImageData,
                backImageUrl: "",
            }
            
        }
        if (cardType === AADHAR_CARD) {
            if(docNumber === "") {
                toast.error("Please enter document number!!", {id: 'errorToast'})
            }
            if(aadhaarFrontData === "") {
                toast.error("Please upload aadhaar card front image!!", {id: 'errorToast'})
            }
            if(aadhaarBackData === "") {
                toast.error("Please upload aadhaar card back image!!", {id: 'errorToast'})
            }
            payload = {
                documentNumber: docNumber,
                documentType: "AadharCard",
                // documentType: "PanCard",
                frontImageUrl: aadhaarFrontData,
                backImageUrl: aadhaarBackData,
            }
        }
        callPostApi(
            Upload_KYC_Document_POST,
            payload,
            {},
            (fileResponse) => {
                if (fileResponse.errorCode === 0) {
                    // const obj = {
                    //     // base64URL: imageFileData.fileBase64,
                    //     imageURL: fileResponse.responsePacket,
                    // };
                    // if (selectedImageType === PAN_CARD_) {
                    //     setPanCardImage(obj);
                    // } else if (selectedImageType === AADHAR_Front) {
                    //     setAadhaarFrontImage(obj);
                    // } else if (selectedImageType === AADHAR_Back) {
                    //     setAadhaarBackImage(obj);
                    // }
                    toast.success(fileResponse.message, {
                        id: "uploadKycImage",
                    });
                    navigate(-1);
                } else {
                    toast.error(fileResponse.message, {
                        id: ERROR_TOAST_ID,
                    });
                }
            }
        );
    };

    const handleDocumentNumber = (e) => {
        const { value } = e.target;
        setDocNumber(value);
    }

    return (
        <Wrapper>
            <CustomHeader title={cardType} />
            <div className="p-3 WithdrawContent">
                {cardType === PAN_CARD && (
                    <div>
                        <div className="yellowCard mb-3">
                            <div className="innerCard py-3">
                                <h6 className="text-black font-weight-600 text-center font-f">
                                    Upload Front Side of PAN Card
                                </h6>
                                <div className="upload-box">
                                    <div className="yellowCard">
                                        <input
                                            className="d-none"
                                            type="file"
                                            onChange={(e) =>
                                                handleImageSelection(e)
                                            }
                                            name="userProfile"
                                            id="fileElement"
                                            ref={fileRefPanCard}
                                            accept="image/*"
                                        />
                                        <div
                                            className="innerCard p-3 text-center"
                        
                                        >
                                            <div onClick={() => handleImageSelectionRef(PAN_CARD_)} className="cursor-pointer">
                                                <span className="uploadImageBox">
                                                    <img
                                                        src={
                                                            panCardImage === ""
                                                                ? "assets/img/panCardIcon.png"
                                                                : panCardImage
                                                        }
                                                        alt="panCardIcon"
                                                    />
                                                </span>
                                                {panCardImage === "" && (
                                                    <h5 className="text-white">
                                                        Upload Photo
                                                    </h5>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ paddingLeft: "55px" }}
                                                id="documentNumber"
                                                placeholder="Pan card number"
                                                value={docNumber}
                                                onChange={(e) => handleDocumentNumber(e)}
                                            />
                    </div>
                )}
                {cardType === AADHAR_CARD && (
                    <div>
                        <div className="yellowCard mb-3">
                            <div className="innerCard py-3">
                                <h6 className="text-black font-weight-600 text-center font-f">
                                    Upload Front and Back of Aadhaar
                                </h6>
                                <div className="upload-box mb-3">
                                    <div className="yellowCard">
                                        <input
                                            className="d-none"
                                            type="file"
                                            onChange={handleImageSelection}
                                            name="userProfile"
                                            id="fileElement"
                                            ref={fileRefAadharFront}
                                            accept="image/*"
                                        />
                                        <div
                                            className="innerCard p-3 text-center"
                                            onClick={() =>
                                                handleImageSelectionRef(
                                                    AADHAR_Front
                                                )
                                            }
                                        >
                                            <span className="uploadImageBox">
                                                <img
                                                    src={
                                                        aadhaarFrontImage === ""
                                                            ? "assets/img/aadhaarBackIcon.png"
                                                            : aadhaarFrontImage
                                                    }
                                                    alt="aadharCardIcon"
                                                />
                                            </span>
                                            {aadhaarFrontImage === "" && (
                                                <h5 className="text-white">
                                                    Upload Front
                                                </h5>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="upload-box">
                                    <div className="yellowCard">
                                        <input
                                            className="d-none"
                                            type="file"
                                            onChange={handleImageSelection}
                                            name="userProfile"
                                            id="fileElement"
                                            ref={fileRefAadharBack}
                                            accept="image/*"
                                        />
                                        <div
                                            className="innerCard p-3 text-center"
                                            onClick={() =>
                                                handleImageSelectionRef(AADHAR_Back)
                                            }
                                        >
                                            <span className="uploadImageBox">
                                                <img
                                                    src={
                                                        aadhaarBackImage === ""
                                                            ? "assets/img/aadhaarBackIcon.png"
                                                            : aadhaarBackImage
                                                    }
                                                    alt="aadhaarBackIcon"
                                                />
                                            </span>
                                            {aadhaarBackImage === "" && (
                                                <h5 className="text-white">
                                                    Upload Back
                                                </h5>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                                <input
                                                type="text"
                                                className="form-control"
                                                style={{ paddingLeft: "55px" }}
                                                id="documentNumber"
                                                placeholder="Aadhaar card number"
                                                value={docNumber}
                                                onChange={(e) => handleDocumentNumber(e)}
                                            />
                    </div>
                )}
                <div className=" position-absolute  end-0 start-0 bottom-0 px-3">
                    <button
                        className="btn custom-yellow-btn w-100"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Wrapper>
    );
};
