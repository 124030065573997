import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../App";
import FooterMenu from "./FooterMenu";
import Wrapper from "./Wrapper";
import { callGetApi } from "../api/ApiCaller";
import {
    GAME_PLAY_SERVICE_URL,
    GET_LEVEL_DETAILS_GET,
    Wallet_Balance_GET,
} from "../api/ApiConst";
import { toast } from "react-hot-toast";
import { NO_ERROR } from "../api/constants";
import { useNavigate } from "react-router-dom";
import { SET_ACTIVE_WALLET_TAB, SET_PROGRESS_DETAILS, SET_WALLET_DETAILS } from "../reducer/actions/utilityActions";
import { convertAmount } from "../utils/CurrencyUtils";
import { AuthTokenKey, getDataFromStore } from "utils/DataStore";
import { InfoIcon, PlusIcon, RupeeIconTo, SettingIcon, UserIcon } from "./common/SvgIcon";
import Loader from "./common/Loader";
import Slider from "react-slick";
import ModalBox from "./common/Modal";
import { ERROR_TOAST_ID } from "utils/Constants";
import LevelProgressBar from "./LevelProgressBar";

const onlinePlayerDataObj = {
    classicPlayer: 0,
    timerPlayer: 0,
    turboPlayer: 0,
    totalPlayer: 0,
};

const Dashboard = () => {
    const [showHowToPlayModal, setShowHowToPlayModal] = useState(false);
    const { authState, dispatchUtility } = useContext(GlobalContext);
    const [onlinePlayerData, setOnlinePlayerData] = useState(0);
    const [show, setShow] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const generatorOnlinePlayer = () => {
        const randomNumber = Math.floor(Math.random() * (20 - 10 + 1)) + 10;
        setOnlinePlayerData(randomNumber);
    }

    useEffect(() => {
        generatorOnlinePlayer();
        let onlinePlayerInterval = setInterval(() => {
            generatorOnlinePlayer();
        }, 30 * 1000);
        return () => {
            clearInterval(onlinePlayerInterval);
        }
    }, [])

    const navigate = useNavigate();

    const handleNavigate = (navigateTo) => {
        dispatchUtility({ type: SET_ACTIVE_WALLET_TAB, payload: navigateTo });
        navigate("/wallet");
    };

    const getWalletDetails = () => {
        callGetApi(Wallet_Balance_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                dispatchUtility({ type: SET_WALLET_DETAILS, payload: response.responsePacket });
            } else {
                toast.error(response.message, { id: ERROR_TOAST_ID });
            }
        });
    };

    const getProgressBarDetails = () => {
        callGetApi(GET_LEVEL_DETAILS_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                let res = response.responsePacket;
                // setProgressBarDetails(res);
                dispatchUtility({ type: SET_PROGRESS_DETAILS, payload: res });
            }
        })
    }

    function receiveMessage(event) {
        if (event.data === "Gameplay") {
            setShow(false);
        } else if (event.data === "Dashboard") {
            setShow(true);
            getWalletDetails();
            getProgressBarDetails();
        }
    }

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);

        return () => {
            window.removeEventListener("message", receiveMessage, false);
        };
    }, []);

    const handleIframeLoad = () => {
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            <Wrapper>
                <div className="position-relative dashboardMain-content-main">
                    <div className={`dashboard-header headerBg${show ? "" : " d-none"}`}>
                        <div className="container-fluid px-2">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex w-100">
                                    <div className="cursor-pointer text-left p-0 " onClick={() => navigate("/profile")}>
                                        <span className="user-profile mt-2">
                                            <img className="rounded-circle" src={authState.userDetails?.profileImgUrl ? authState.userDetails?.profileImgUrl : "assets/img/avtaar-user.png"} />
                                            {/* <span>{authState?.userDetails?.levelTitle}</span> */}
                                            {/* <img className="badges" src="assets/img/badges-1.png" alt="levelIcon" /> */}
                                            {authState?.userDetails?.levelIconImageUrl && <img className="badges" src={authState?.userDetails?.levelIconImageUrl} alt="levelIcon" />}

                                        </span>
                                        <div className="user-content">
                                            <p className="text-white ">Profile</p>
                                        </div>
                                    </div>
                                    <LevelProgressBar />
                                </div>

                                <div className="p-0 text-right pt-1">
                                    <div className="d-flex align-items-center justify-content-end">
                                        {/* <span className="amountBtn cursor-pointer text-white" onClick={() => handleNavigate("Add Cash")}>
                                            <span className="rupeeIcon">
                                                <RupeeIconTo />

                                            </span>
                                            <span className="px-2 ">{convertAmount(utilityState.walletDetails?.walletBalance)} </span>
                                            <span className="plus-icon">
                                                <PlusIcon />
                                            </span>
                                        </span> */}
                                        <div className="d-flex align-items-center gap-2">
                                            <span className="cursor-pointer" onClick={() => navigate("/setting")}><SettingIcon /></span>
                                            <span className="cursor-pointer" onClick={() => setShowHowToPlayModal(true)}><InfoIcon /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="onlinePlayer">
                            <span className="mr-2">
                                <UserIcon />
                            </span>
                            Total Online Players:
                            {onlinePlayerData}
                        </span>
                    </div>
                    {
                        show && <div className="mt-3">
                            <Slider>
                                <img src="assets/img/banner_001.png" alt="homeSlider1" />
                            </Slider>
                        </div>
                    }
                    <div className="dashboardMain-content">
                        <iframe
                            src={GAME_PLAY_SERVICE_URL.replace("{accessToken}", getDataFromStore(AuthTokenKey).accessToken)}
                            width={"100%"}
                            height={"100%"}
                            className={`${isLoading ? 'd-none' : ''}`}
                            frameBorder="0"
                            onLoad={handleIframeLoad}
                            onError={() => setIsLoading(false)}
                        />
                        {isLoading ? (
                            <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-transparent">
                                <Loader />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    {show && <FooterMenu />}
                </div>
                <ModalBox show={showHowToPlayModal} toggleModel={() => setShowHowToPlayModal(false)} modalClass={"sliderBlockModal h-100"}>
                    <div className="modal-content bg-yellowGradient border-none h-100 ">
                        <div className="modal-body p-0 flex-column d-flex">
                            <div className=" modal-body-header">
                                <button type="button" className="btn-close" style={{ right: "15px" }} onClick={() => setShowHowToPlayModal(false)}>
                                    <span className="cursor-pointer">
                                        <img src="assets/img/modal-close.png" alt="" style={{ width: "40px" }} />
                                    </span>
                                </button>
                                <h5 className="modal-title text-black font-size-18 font-weight-600 px-2 text-center" id="exampleModalLongTitle">
                                    How To Play
                                </h5>
                            </div>
                            <div className="d-flex flex-column flex-fill">
                                {/* <div className="iframeBox">
                                                <div className="video-container">
                                                    <CustomVideoPlayer
                                                        videoSrc="https://youtube.com/shorts/iitnlJopu-k?si=fmu1p3yS8Xn2QAax%0A"
                                                        posterImg={PosterImg}
                                                    />
                                                </div>
                                            </div> */}
                                <div className="px-4 mt-2 ">
                                    <a className=" text-decoration-underline text-yellow fw-600" href="https://youtube.com/shorts/iitnlJopu-k?si=fmu1p3yS8Xn2QAax%0A" target="_blank">How To Play(Video)</a>
                                </div>
                                <div className=" how-to-play flex-fill p-3 position-relative overflow-auto">
                                    <ul className=" position-absolute">
                                        <li>Players: 2-4, each with a unique color.</li>
                                        <li>Start & Movement: Roll the dice to move tokens and score points.</li>
                                        <li>Capturing: Land on an opponent’s token to send it home and score points.</li>
                                        <li>Question mark (?) zone: Answer a question to move 6 steps and score points.</li>
                                        <li>Safe Zones: Star-marked squares protect tokens. Two same-colored tokens block captures.</li>
                                        <li>Winning: Highest points after given turns or within the time limit wins.</li>
                                        <li>Remember: Turn Limit; total 25 turns given to play, 🤍🤍🤍; 3 chances are given to miss a turn</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            </Wrapper>
        </React.Fragment>
    );
};

export default Dashboard;
