import axios from "axios";
import { AuthTokenKey, deleteDataFromStore, getDataFromStore, UserDetailsKey } from "utils/DataStore";
import { UNAUTHORIZED } from "../api/constants";

const handleUnauthorized = () => {
    deleteDataFromStore(AuthTokenKey);
    deleteDataFromStore(UserDetailsKey);
    document.location.href = '/';
    window.sendMessageToVuplex("Logout", {});
};

const getHeaders = (headerMap) => {
    let authToken = getDataFromStore(AuthTokenKey);
    if(authToken === null) {
        handleUnauthorized();
        return;
    }
    headerMap["Authorization"] = authToken.accessToken;
    return headerMap;
};

const handleSuccess = (result, successCallBack) => {
    if (successCallBack != null) {
        if (result && result !== undefined) {
            if (result.data.errorCode === UNAUTHORIZED) {
                handleUnauthorized();
            }
            successCallBack(result.data);
        }
    }
};

const handleFailure = (error, failedCallBack) => {
    // toast.error(error?.response?.data?.message ? error?.response?.data?.message : error.message, {id: "ApiCallerError"});
    if (failedCallBack != null) {
        failedCallBack(error);
    }
};

export const callPostApiWithoutAuth = (url, data, successCallBack, failedCallBack) => {
    axios
        .post(url, data)
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callGetApiWithoutAuth = (url, successCallBack, failedCallBack) => {
    axios
        .get(url)
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callGetApi = (url, headerMap, successCallBack, failedCallBack) => {
    axios
        .get(url, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callPostApi = (url, data, headerMap, successCallBack, failedCallBack) => {
    axios
        .post(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callPutApi = (url, data, headerMap, successCallBack, failedCallBack) => {
    axios
        .put(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callPatchApi = (url, data, headerMap, successCallBack, failedCallBack) => {
    axios
        .patch(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callDeleteApi = (url, headerMap, successCallBack, failedCallBack) => {
    axios
        .delete(url, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};
